import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { Row, Card, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import SmartTable from "Components/Common/SmartTable";
import { useFetchWeeklyProspects, currencyFormat } from "api";
import {
    format,
    startOfWeek,
    endOfWeek,
    subWeeks,
    addWeeks,
    isAfter,
    endOfDay,
} from "date-fns";
import { MeetingResultForm } from "./MeetingResultForm";
import ClientMeetingForm from "./ClientMeetingForm";
import PageHeader from "Components/Common/PageHeader";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import { array } from "yup";

function AdvisorWeeklyReports() {
    //meta title
    document.title = "Advisor Weekly Reports | Ridgeline Metrics Dashboard ";

    const { advisor } = useParams();
    const navigate = useNavigate();

    // Get current date
    const [currentDate, setCurrentDate] = useState(new Date());

    // Find the start and end of the current week
    const startDate = format(
        startOfWeek(currentDate, { weekStartsOn: 1 }),
        "yyyy-MM-dd"
    ); // 1 means Monday
    const endDate = format(
        endOfWeek(currentDate, { weekStartsOn: 1 }),
        "yyyy-MM-dd"
    );

    const { data, isLoading, refetch } = useFetchWeeklyProspects(
        format(currentDate, "yyyy-MM-dd"),
        advisor
    );

    const [objects, setObjects] = useState<any>(null);

    const [modal, setModal] = useState(false);
    const [modalClient, setModalClient] = useState(false);

    const [totalSales, setTotalSales] = useState();
    const [firstMeetings, setFirstMeetings] = useState();
    const [signings, setSignings] = useState();

    const [current, setCurrent] = useState<any>(null);

    const [curResult, setCurResult] = useState<any>(array());

    const toggle = () => {
        setModal(!modal);
    };

    const toggleClient = () => {
        setModalClient(!modalClient);
    };

    const movePrevWeek = () => {
        setCurrentDate((prevDate) => subWeeks(prevDate, 1));
    };

    const moveNextWeek = () => {
        setCurrentDate((prevDate) => {
            const nextWeekDate = addWeeks(prevDate, 1);
            const today = endOfDay(new Date());

            if (isAfter(nextWeekDate, today)) return currentDate;
            else return nextWeekDate;
        });
    };

    const moveCurrWeek = () => {
        setCurrentDate(new Date());
    };

    const onAddResult = (row: any) => {
        setCurrent(row);
        toggle();
    };

    useEffect(() => {
        if (data?.prospects) {
            const firstMeetingCount = data?.results.filter(
                (result: any) => result?.status === "FIRST_MEETING"
            ).length;
            const secondMeetingCount = data?.results.filter(
                (result: any) => result?.status === "SECOND_MEETING"
            ).length;
            const signingCount = data?.results.filter(
                (result: any) => result?.status === "SIGNING_MEETING"
            ).length;
            const totalSalesMeetingCount =
                firstMeetingCount + secondMeetingCount + signingCount;

            setFirstMeetings(firstMeetingCount);
            setSignings(signingCount);
            setTotalSales(totalSalesMeetingCount);

            const currentClientsArray =
                data?.currentClients?.map((currentClient) => ({
                    id: currentClient.id,
                    firstName: currentClient.firstName || null,
                    lastName: currentClient.lastName || null,
                    meetingDate: currentClient.meetingDate || null,
                    newMoney: currentClient.assets || 0,
                    status: null,
                    meeting: { name: null },
                })) || [];

            // Combine prospects and current clients into a single array
            setObjects([...data.prospects, ...currentClientsArray]);

            if (data?.results) {
                const resultsByProspectId = data?.results.reduce(
                    (acc, result) => {
                        if (!acc[result.prospect.id]) {
                            acc[result.prospect.id] = [];
                        }
                        acc[result.prospect.id].push(result);
                        return acc;
                    },
                    {}
                );

                setCurResult(resultsByProspectId);

                data?.prospects.forEach((prospect) => {
                    const prospectResults =
                        resultsByProspectId[prospect.id] || [];
                    prospectResults.forEach((result) => {
                        if (result.extraStatus !== "")
                            prospect.result = result.extraStatus;
                        else prospect.result = result.meetingStatus;
                    });
                });
            }
        }
    }, [data]);

    // useEffect(() => {
    //     setObjects(data?.prospects);
    // }, [data]);

    const handleProspectDetail = useCallback(
        (prospect: any) => {
            navigate(`/advisor/prospect/${prospect.id}/detail`);
        },
        [navigate]
    );

    const handleAddClientMeet = () => {
        toggleClient();
    };

    const columns = useMemo(
        () => [
            {
                Header: "Last Name",
                accessor: "lastName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    var meetingType = cellProps.row.original.meeting.name;

                    if (meetingType) {
                        return (
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const row = cellProps.row.original;
                                    handleProspectDetail(row);
                                }}
                            >
                                {cellProps.row.original.lastName}
                            </Link>
                        );
                    } else {
                        return <span>{cellProps.row.original.lastName}</span>;
                    }
                },
            },
            {
                Header: "First Name",
                accessor: "firstName",
                Filter: false,
                filterable: true,
            },
            // {
            //   Header: "Meeting Name",
            //   accessor: "meeting.name",
            //   Filter: false,
            // },
            {
                Header: "Investible Assets",
                accessor: "newMoney",
                Filter: false,
                filterable: true,
                Cell: ({ value }) => {
                    return <span>{currencyFormat(value)}</span>;
                },
            },
            {
                Header: "Meeting Result",
                accessor: "status",
                Filter: false,
                filterable: true,
                Cell: (props: any) => {
                    const { original: row } = props.cell.row;
                    if (
                        curResult[row.id] &&
                        row.meeting.id !==
                            curResult[row.id][curResult[row.id].length - 1]
                                .meeting.id
                    ) {
                        return curResult[row.id][curResult[row.id].length - 1]
                            .status;
                    } else {
                        return row.status;
                    }
                },
            },
            {
                Header: "Meeting Type",
                accessor: "meeting.name",
                Filter: false,
                Cell: (props: any) => {
                    const { original: row } = props.cell.row;
                    if (
                        curResult[row.id] &&
                        row.meeting.id !==
                            curResult[row.id][curResult[row.id].length - 1]
                                .meeting.id
                    ) {
                        return curResult[row.id][curResult[row.id].length - 1]
                            .meeting.name;
                    } else {
                        return row.meeting.name;
                    }
                },
            },
            // {
            //   Header: "Refer",
            //   accessor: "result",
            //   Filter: false,
            //   filterable: true,
            // },
            {
                Header: "Meeting Date",
                accessor: "meetingDate",
                Filter: false,
                filterable: true,
                Cell: (props: any) => {
                    const { original: row } = props.cell.row;
                    if (
                        curResult[row.id] &&
                        row.meeting.id !==
                            curResult[row.id][curResult[row.id].length - 1]
                                .meeting.id
                    ) {
                        return curResult[row.id][curResult[row.id].length - 1]
                            .meetingDate;
                    } else {
                        return row.meetingDate;
                    }
                },
            },
            {
                Header: "Actions",
                Filter: false,
                Cell: (props: any) => {
                    const { original: row } = props.cell.row;
                    if (
                        (row.status === "FIRST_MEETING" ||
                            row.status === "SECOND_MEETING" ||
                            row.status === "SIGNING_MEETING") &&
                        startDate <= row.meetingDate &&
                        endDate >= row.meetingDate
                    )
                        return (
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => onAddResult(row)}
                                >
                                    Set Result
                                </button>
                            </div>
                        );
                },
            },
        ],
        [curResult, startDate, endDate, handleProspectDetail]
    );

    const notificationData = [
        {
            title: "Week Of",
            content:
                startDate.replaceAll("-", "/") +
                " - " +
                endDate.replaceAll("-", "/"),
        },
        {
            title: "Total Sales Meetings Scheduled this week",
            content: totalSales || 0,
        },
        {
            title: "First Meetings Scheduled this week",
            content: firstMeetings || 0,
        },
        {
            title: "Siginings Scheduled this week",
            content: signings || 0,
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Advisor" breadcrumbItem="Weekly Meetings" /> */}
                    <PageHeader
                        title="Advisor Weekly Report"
                        onPrevWeek={movePrevWeek}
                        onNextWeek={moveNextWeek}
                        onCurrWeek={moveCurrWeek}
                    />
                    <Row>
                        {notificationData.map((data, index) => {
                            return (
                                <NotificationMiniCard
                                    title={data.title}
                                    content={data.content}
                                    key={index}
                                    size={3}
                                />
                            );
                        })}
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <h2 className="flex-grow-1">
                                        Active Prospect
                                    </h2>
                                    <div className="flex-shrink-0">
                                        <Link
                                            to="#"
                                            onClick={() =>
                                                handleAddClientMeet()
                                            }
                                            className="btn btn-success me-1"
                                        >
                                            {" "}
                                            <i className="bx bx-plus-circle"></i>{" "}
                                            Add Client Meeting
                                        </Link>
                                        <Link
                                            onClick={() => refetch()}
                                            to="#"
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                                <SmartTable
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={objects || []}
                                    customPageSize={30}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    isShowingPageLength={true}
                                    customPageSizeOption={true}
                                    tableClass="table-bordered align-middle nowrap mt-2"
                                    paginationDiv="col-12"
                                    pagination="pagination justify-content-end pagination-rounded"
                                />
                            </CardBody>
                        </Card>
                    </Row>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {current?.meeting?.name} Result : {current?.fullName}
                </ModalHeader>
                <ModalBody>
                    <MeetingResultForm current={current} rtoggle={toggle} />
                </ModalBody>
            </Modal>
            <Modal isOpen={modalClient} toggle={toggleClient}>
                <ModalHeader toggle={toggleClient} tag="h4">
                    Add Client Meeting
                </ModalHeader>
                <ModalBody>
                    <ClientMeetingForm
                        toggle={toggleClient}
                        advisor={advisor}
                    />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default AdvisorWeeklyReports;
