import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Col, Row, Button } from "reactstrap";
import { SmartInput } from "Components/Common/SmartInput";
import { SmartCurrency } from "Components/Common/SmartCurrency";
import { SmartCheck } from "Components/Common/SmartCheck";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { useFetchClients, useCreateClient } from "api";

interface Client {
    id: number;
    fullName: string;
    newMoney?: number;
}

function ClientMeetingForm({ toggle, advisor }) {
    const createClientMutation = useCreateClient();
    const { data: fetchedClients, isLoading: clients_loading } = useFetchClients();
    const [clients, setClients] = useState<Client[]>([]);

    const initialValues = {
        advisor: {
            id: advisor
        },
        firstName: "",
        lastName: "",
        clientName: "",
        meetingDate: null,
        assets: 0,
        reportedAssets: 0,
        addedAssets: 0,
        prospect: null,
        delivery: false,
    };

    const validationSchema = Yup.object({
        // lastName: Yup.string().required("Please Input Last Name"),
        // firstName: Yup.string().required("Please Input First Name"),
        clientName: Yup.number().required("Client Name is Required"),
        meetingDate: Yup.date().nullable().required("Revenue Date Required"),
        assets: Yup.number().when("clientName", {
            is: (value) => value === "0",
            then: Yup.number().required("New Assets is Required"),
            otherwise: Yup.number().notRequired(),
        })
    });

    useEffect(() => {
        if (fetchedClients) {
            const advisor_clients = fetchedClients?.filter(
                (client) => client.advisor.id == advisor
            );
            setClients([
                { id: 0, fullName: "Current Client" },
                ...advisor_clients,
            ]);
        }
    }, [fetchedClients, advisor]);

    const handleSubmit = async (values) => {
        if (values.clientName !== "0") {
            const clientInfo = fetchedClients.find(
                (client) => client.id === Number(values.clientName)
            );
            values.prospect = clientInfo;
            values.firstName = "";
            values.assets = Number(values.reportedAssets || 0) + Number(values.addedAssets || 0);
        }
        // alert(JSON.stringify(values, null, 2));

        await createClientMutation.mutateAsync(values);
        toggle();
    };

    return (
        <React.Fragment>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <SmartInput
                            isLoading={clients_loading}
                            as="select"
                            name="clientName"
                            label="Client Name"
                            value={values.clientName}
                            onChange={(e) => {
                                const { value } = e.target;
                                const selectedClient = clients.find(client => client.id == Number(value));

                                setFieldValue("clientName", value);

                                setFieldValue("reportedAssets", selectedClient?.newMoney || 0, true);

                                setTimeout(() => {
                                    setFieldValue("reportedAssets", selectedClient?.newMoney || 0, true);
                                }, 0);
                            }}
                        >
                            <option value="">Select Client Name</option>
                            {clients?.map((client) => (
                                <option key={client.id} value={client.id}>
                                    {client.fullName}
                                </option>
                            ))}
                        </SmartInput>

                        {values.clientName === "0" && (
                            <Row className="mb-2">
                                <SmartInput
                                    type="text"
                                    name="firstName"
                                    label="First Name"
                                    size="6"
                                />
                                <SmartInput
                                    type="text"
                                    name="lastName"
                                    label="Last Name"
                                    size="6"
                                />
                            </Row>
                        )}

                        <SmartDatePicker
                            name="meetingDate"
                            label="Meeting Date"
                        />

                        {values.clientName === "0" && (
                            <SmartCurrency
                                name="assets"
                                label="New Assets"
                            />
                        )}

                        {values.clientName !== "0" && (
                            <SmartCurrency
                                key={`reportedAssets-${values.reportedAssets}`}
                                name="reportedAssets"
                                label="Reported New Assets"
                                isDisabled={true}
                            />
                        )}

                        {values.clientName !== "0" && (
                            <SmartCurrency
                                name="addedAssets"
                                label="Additional New Assets"
                            />
                        )}

                        {values.clientName !== "0" && (
                            <SmartCheck
                                name="delivery"
                                label="Wrap Up/Delivery Meeting"
                            />
                        )}

                        <Row>
                            <Col>
                                <div className="text-end">
                                    <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                    >
                                        <i className="bx bx-save"></i> Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
}

export default ClientMeetingForm;