import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
//redux

import { Row, Card, CardBody } from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import SmartTable from "Components/Common/SmartTable";
import {
    useFetchCsuiteWeekProspects,
    useFetchAdvisors,
    currencyFormat,
} from "api";
import { useFetchProspectsById, useFetchProspectResultsById } from "api";
import {
    format,
    startOfWeek,
    endOfWeek,
    subWeeks,
    addWeeks,
    isAfter,
    endOfDay,
} from "date-fns";
import PageHeader from "Components/Common/PageHeader";
import NotificationMiniCard from "Components/Common/NotificationMiniCard";
import { array } from "yup";

function CsuiteWeeklyReports() {
    //meta title
    document.title = "Firm Weekly Reports | Ridgeline Metrics Dashboard ";

    const navigate = useNavigate();

    // Get current date
    const [currentDate, setCurrentDate] = useState(new Date());

    // Find the start and end of the current week
    const startDate = format(
        startOfWeek(currentDate, { weekStartsOn: 1 }),
        "yyyy-MM-dd"
    ); // 1 means Monday
    const endDate = format(
        endOfWeek(currentDate, { weekStartsOn: 1 }),
        "yyyy-MM-dd"
    );

    const { data: advisors } = useFetchAdvisors();
    const { data: weekData } = useFetchCsuiteWeekProspects(
        format(currentDate, "yyyy-MM-dd")
    );

    const [objects, setObjects] = useState<any>(null);
    const [weekSummary, setWeekSummary] = useState<any>(null);

    const [firstMeetings, setFirstMeetings] = useState();
    const [signings, setSignings] = useState();
    const [signedAssets, setSignedAssets] = useState();

    const [curResult, setCurResult] = useState<any>(array());

    const movePrevWeek = () => {
        setCurrentDate((prevDate) => subWeeks(prevDate, 1));
    };

    const moveNextWeek = () => {
        setCurrentDate((prevDate) => {
            const nextWeekDate = addWeeks(prevDate, 1);
            const today = endOfDay(new Date());

            if (isAfter(nextWeekDate, today)) return currentDate;
            else return nextWeekDate;
        });
    };

    const moveCurrWeek = () => {
        setCurrentDate(new Date());
    };

    useEffect(() => {
        if (weekData) {
            const firstMeetingCount = weekData?.results.filter(
                (result: any) =>
                    result?.meetingStatus === "KEPT" &&
                    result?.status === "FIRST_MEETING" &&
                    result?.meetingDate >= startDate &&
                    result?.meetingDate <= endDate
            ).length;
            const signingCount = weekData?.results.filter(
                (result: any) =>
                    result?.meetingStatus === "KEPT" &&
                    result?.status === "SIGNING_MEETING" &&
                    result?.meetingDate >= startDate &&
                    result?.meetingDate <= endDate
            ).length;

            const signedAssets =
                weekData?.prospects
                    .filter(
                        (prospect: any) =>
                            prospect.status === "SIGNED" &&
                            prospect?.meetingDate >= startDate &&
                            prospect?.meetingDate <= endDate
                    )
                    .reduce(
                        (sum: number, prospect: any) => sum + prospect.newMoney,
                        0
                    ) +
                weekData?.current_clients?.reduce(
                    (sum: number, client: any) => sum + client.assets,
                    0
                );

            if (advisors) {
                const resultsByAdvisorId = weekData?.results.reduce(
                    (acc, result) => {
                        if (!acc[result?.prospect?.advisor.id]) {
                            acc[result?.prospect?.advisor.id] = [];
                        }
                        acc[result?.prospect?.advisor.id].push(result);
                        return acc;
                    },
                    {}
                );

                const resultsByProspectId = weekData?.results.reduce(
                    (acc, result) => {
                        if (!acc[result?.prospect?.id]) {
                            acc[result?.prospect?.id] = [];
                        }
                        acc[result?.prospect?.id].push(result);
                        return acc;
                    },
                    {}
                );

                setCurResult(resultsByProspectId);

                advisors.forEach((advisor) => {
                    // Initialize advisor properties
                    advisor.first_meeting = 0;
                    advisor.second_meeting = 0;
                    advisor.signing_meeting = 0;
                    advisor.new_assets_signed = 0;
                    advisor.client_meeting = 0;
                    advisor.investable_assets = 0;

                    const prospectResults =
                        resultsByAdvisorId[advisor.id] || [];

                    prospectResults.forEach((result) => {
                        if (
                            result.meetingStatus === "KEPT" &&
                            result.meetingDate >= startDate &&
                            result.meetingDate <= endDate
                        ) {
                            if (result.status === "FIRST_MEETING")
                                advisor.first_meeting++;
                            else if (result.status === "SECOND_MEETING")
                                advisor.second_meeting++;
                            else if (result.status === "SIGNING_MEETING")
                                advisor.signing_meeting++;
                        }
                    });

                    weekData?.prospects.forEach((prospect) => {
                        if (prospect.advisor?.id === advisor.id) {
                            if (
                                prospect.status === "SIGNED" &&
                                prospect?.meetingDate >= startDate &&
                                prospect?.meetingDate <= endDate
                            ) {
                                advisor.new_assets_signed += prospect.newMoney;
                            }
                        }
                    });

                    weekData?.current_clients.forEach((client) => {
                        if (client.createdBy === advisor.username) {
                            advisor.new_assets_signed += client.assets;
                            advisor.client_meeting++;
                        }
                    });
                });
            }

            let newAdvisor = [...advisors];
            setWeekSummary(newAdvisor);
            setObjects(weekData?.prospects);
            setFirstMeetings(firstMeetingCount);
            setSignings(signingCount);
            setSignedAssets(signedAssets);
        }
    }, [currentDate, startDate, endDate, weekData, advisors]);

    const handleProspectDetail = useCallback(
        (prospect: any) => {
            navigate(`/csuite/prospect/${prospect.id}/detail`);
        },
        [navigate]
    );

    const columns = useMemo(
        () => [
            {
                Header: "Last Name",
                accessor: "lastName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    return (
                        <Link
                            to="#"
                            className="btn btn-sm btn-soft-info"
                            onClick={(e) => {
                                e.preventDefault();
                                const row = cellProps.row.original;
                                handleProspectDetail(row);
                            }}
                        >
                            {cellProps.row.original.lastName}
                        </Link>
                    );
                },
            },
            {
                Header: "Advisor",
                accessor: "advisor.fullName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    if (
                        cellProps?.row.original.advisor?.fullName !==
                        "null null"
                    )
                        return (
                            <span>
                                {cellProps?.row.original.advisor?.fullName}
                            </span>
                        );
                    else
                        return (
                            <span>
                                {cellProps?.row.original.advisor?.username}
                            </span>
                        );
                },
            },
            // {
            //   Header: "Meeting Name",
            //   accessor: "meeting.name",
            //   Filter: false,
            // },
            {
                Header: "Investible Assets",
                accessor: "newMoney",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    const id = cellProps?.row.original.id;
                    const prospectData = useFetchProspectsById(
                        id ? parseInt(id) : 0
                    )?.data;
                    if (prospectData?.status !== "SIGNED") {
                        let newMoney = prospectData?.newMoney;
                        let formattedValue = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(newMoney);

                        return <span>{formattedValue}</span>;
                    }
                    if (
                        prospectData?.status === "SIGNED" &&
                        prospectData?.meetingDate >= startDate &&
                        prospectData?.meetingDate <= endDate
                    ) {
                        let newMoney = prospectData?.newMoney;
                        let formattedValue = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(newMoney);

                        return <span>{formattedValue}</span>;
                    } else {
                        let formattedValue = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(0);

                        return <span>{formattedValue}</span>;
                    }
                },
            },
            {
                Header: "Meeting Result",
                accessor: "meetingStatus",
                Filter: false,
                filterable: true,
                Cell: (props: any) => {
                    const { original: row } = props.cell.row;
                    let status =
                        curResult[row.id][curResult[row.id].length - 1].status;
                    let extraStatus =
                        curResult[row.id][curResult[row.id].length - 1]
                            .extraStatus;
                    let meetingStatus =
                        curResult[row.id][curResult[row.id].length - 1]
                            .meetingStatus;

                    if (
                        curResult[row.id] &&
                        row.meeting.id !==
                        curResult[row.id][curResult[row.id].length - 1]
                            .meeting.id
                    ) {
                        if (status === "SIGNED") {
                            return "SIGNED";
                        } else if (status !== "SIGNED") {
                            if (meetingStatus === "RESCHEDULED") {
                                return "KEPT/Pending Next Meeting Schedule";
                            } else if (meetingStatus === "KEPT") {
                                if (extraStatus === "RESCHEDULED")
                                    return "KEPT/Pending Next Meeting Schedule";
                                else
                                    return meetingStatus + "/Meeting Scheduled";
                            } else if (meetingStatus === "CANCELLED") {
                                return meetingStatus + "/" + extraStatus;
                            } else if (
                                meetingStatus === null &&
                                extraStatus === null
                            ) {
                                return "Pending";
                            }
                        }
                    } else {
                        let status = row.status;

                        if (status === "SIGNED") {
                            return "SIGNED";
                        } else if (status === "WTD") {
                            if (meetingStatus !== null) {
                                return meetingStatus + "/" + status;
                            }
                        } else if (status === "TUD") {
                            if (meetingStatus !== null) {
                                return meetingStatus + "/" + status;
                            }
                        } else if (
                            status !== "SIGNED" &&
                            status !== "WTD" &&
                            status !== "TUD"
                        ) {
                            if (meetingStatus === "RESCHEDULED") {
                                return "KEPT/Pending Next Meeting Schedule";
                            } else if (meetingStatus === "KEPT") {
                                if (extraStatus === "RESCHEDULED")
                                    return "KEPT/Pending Next Meeting Schedule";
                                else
                                    return meetingStatus + "/Meeting Scheduled";
                            } else if (meetingStatus === "CANCELLED") {
                                return meetingStatus + "/" + extraStatus;
                            } else if (
                                meetingStatus === null &&
                                extraStatus === null
                            ) {
                                return "Pending";
                            }
                        }
                    }
                },
            },
            {
                Header: "Meeting Type",
                accessor: "meeting.name",
                Filter: false,
                Cell: (props: any) => {
                    const { original: row } = props.cell.row;
                    if (
                        curResult[row.id] &&
                        row.meeting.id !==
                        curResult[row.id][curResult[row.id].length - 1]
                            .meeting.id
                    ) {
                        return curResult[row.id][curResult[row.id].length - 1]
                            .meeting.name;
                    } else {
                        return row.meeting.name;
                    }
                },
            },
            // {
            //   Header: "Refer",
            //   accessor: "result",
            //   Filter: false,
            //   filterable: true,
            // },
            {
                Header: "Meeting Date",
                accessor: "meetingDate",
                Filter: false,
                filterable: true,
                Cell: (props: any) => {
                    const { original: row } = props.cell.row;
                    if (
                        curResult[row.id] &&
                        row.meeting.id !==
                        curResult[row.id][curResult[row.id].length - 1]
                            .meeting.id
                    ) {
                        return curResult[row.id][curResult[row.id].length - 1]
                            .meetingDate;
                    } else {
                        return row.meetingDate;
                    }
                },
            },
            // {
            //   Header: "Actions",
            //   Filter: false,
            //   Cell: (props: any) => {
            //     const { original: row } = props.cell.row;
            //     return (
            //       <div className="d-flex justify-content-center">
            //         <button
            //           className="btn btn-primary btn-sm"
            //           onClick={() => onAddResult(row)}
            //         >
            //           Add Result
            //         </button>
            //       </div>
            //     );
            //   },
            // }
        ],
        [curResult, handleProspectDetail]
    );

    const advisorColumns = useMemo(
        () => [
            {
                Header: "Advisor Name",
                accessor: "fullName",
                Filter: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    if (cellProps?.row.original.fullName !== "null null")
                        return <span>{cellProps?.row.original.fullName}</span>;
                    else return <span>{cellProps?.row.original.username}</span>;
                },
            },
            {
                Header: "First Meeting Kept",
                accessor: "first_meeting",
                Filter: false,
            },
            {
                Header: "Second Meeting Kept",
                accessor: "second_meeting",
                Filter: false,
            },
            {
                Header: "Signing Meeting Kept",
                accessor: "signing_meeting",
                Filter: false,
            },
            {
                Header: "New Assets Signed",
                accessor: "new_assets_signed",
                Filter: false,
                filterable: true,
                Cell: ({ value }) => {
                    return <span>{currencyFormat(value)}</span>;
                },
            },
            {
                Header: "Client Meetings",
                accessor: "client_meeting",
                Filter: false,
                filterable: true,
            },
            // {
            //   Header: "Investible Assets",
            //   accessor: "investable_assets",
            //   Filter: false,
            //   filterable: true,
            //   Cell: ({ value }) => {
            //     return <span>{currencyFormat(value)}</span>;
            //   },
            // }
            // {
            //   Header: "Actions",
            //   Filter: false,
            //   Cell: (props: any) => {
            //     const { original: row } = props.cell.row;
            //     return (
            //       <div className="d-flex justify-content-center">
            //         <button
            //           className="btn btn-primary btn-sm"
            //           onClick={() => onAddResult(row)}
            //         >
            //           Add Result
            //         </button>
            //       </div>
            //     );
            //   },
            // }
        ],
        []
    );

    const notificationData = [
        {
            title: "Week Of",
            content:
                startDate.replaceAll("-", "/") +
                " - " +
                endDate.replaceAll("-", "/"),
        },
        {
            title: "Total New Assets Signed",
            content: currencyFormat(signedAssets),
        },
        {
            title: "First Meetings Kept",
            content: firstMeetings || 0,
        },
        {
            title: "Signing Meetings Kept",
            content: signings || 0,
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeader
                        title="Firm Weekly Summary"
                        onPrevWeek={movePrevWeek}
                        onNextWeek={moveNextWeek}
                        onCurrWeek={moveCurrWeek}
                    />
                    <Row>
                        {notificationData.map((data, index) => {
                            return (
                                <NotificationMiniCard
                                    title={data.title}
                                    content={data.content}
                                    key={index}
                                    size={3}
                                />
                            );
                        })}
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <h2>Advisor Week Summary</h2>
                                <SmartTable
                                    columns={advisorColumns}
                                    data={weekSummary || []}
                                    customPageSize={30}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    isShowingPageLength={true}
                                    customPageSizeOption={true}
                                    tableClass="table-bordered align-middle nowrap mt-2"
                                    paginationDiv="col-12"
                                    pagination="pagination justify-content-end pagination-rounded"
                                />
                            </CardBody>
                        </Card>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <h2>All Prospect</h2>
                                <SmartTable
                                    columns={columns}
                                    data={objects || []}
                                    customPageSize={30}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    isShowingPageLength={true}
                                    customPageSizeOption={true}
                                    tableClass="table-bordered align-middle nowrap mt-2"
                                    paginationDiv="col-12"
                                    pagination="pagination justify-content-end pagination-rounded"
                                />
                            </CardBody>
                        </Card>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CsuiteWeeklyReports;
